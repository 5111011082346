<template>
  <div id="submitList" v-cloak>
    <subpagelayout :stateArr="stateArr" :isEmptyData="isEmptyData">
      <template v-slot:rightmain>
        <publicTable
          v-if="!isEmptyData"
          :listArr="submitLisyArr"
          type="submitList"
        />
      </template>
    </subpagelayout>
  </div>
</template>

<script>
import subpagelayout from "../../components/subPageLayout";
import publicTable from "../../components/publicTable";
export default {
  data() {
    return {
      stateArr: [
        {
          num: "",
          state: "全部",
          color: false
        },
        {
          num: "",
          state: "已处理",
          color: true
        },
        {
          num: "",
          state: "未处理",
          color: false
        }
      ],
      isEmptyData: false,
      submitLisyArr: []
    };
  },
  components: {
    subpagelayout,
    publicTable
  },
  mounted() {
    //获取状态
    this.$http
      .get("report/api/GetUPreCount", {
        params: {
          account: this.$store.state.user.account
        }
      })
      .then(response => {
        if (response.data.state) {
          let deal = response.data.data.filter(m => {
            return (
              m.State === 4 || m.State === 5 || m.State === 6 || m.State === 7
            );
          });
          let udeal = response.data.data.filter(m => {
            return (
              m.State === 0 || m.State === 1 || m.State === 2 || m.State === 3
            );
          });
          this.stateArr[0].num = response.data.data.reduce((total, num) => {
            return total + num.Num;
          }, 0);
          this.stateArr[1].num = deal.reduce((total, num) => {
            return total + num.Num;
          }, 0);
          this.stateArr[2].num = udeal.reduce((total, num) => {
            return total + num.Num;
          }, 0);
        } else {
          console.log(response.data.message);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
    // 获取提交列表
    this.$http
      .get("report/api/GetUserList", {
        params: {
          account: this.$store.state.user.account,
          state: "all"
        }
      })
      .then(response => {
        if (response.data.state) {
          if (response.data.data.length > 0) {
            this.submitLisyArr = response.data.data;
          } else {
            this.isEmptyData = true;
          }
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
};
</script>

<style lang="less" scoped>
.public-table {
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}
</style>
